import React, { useState, useContext, useEffect, useRef } from "react";
import { styled } from '@mui/material/styles';
import { ContextApp } from "../utils/Context";
import './Chat.css';
import axios from "axios";
import CustomizedTables from "../utils/CustomizedTables";
import { Button, Modal, Box, Typography, Select, MenuItem, IconButton } from '@mui/material'; // Importing Material-UI Button
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import robotIcon from '../pics/robot5.png';
import { FcBusinessman } from "react-icons/fc"; // Businessman icon for bot
import { Avatar } from '@mui/material'; // Importing Material-UI Avatar
import { API } from "./MyApi";
import * as XLSX from 'xlsx';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Icon for file
import { Snackbar, Alert } from '@mui/material';
import Plot from 'react-plotly.js';
import MarkdownFormat from '../utils/MarkdownFormat';
import JSZip from "jszip";

const API_BASE_URL = process.env.REACT_APP_BASE_API_ENDPOINT;
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '18px',
  p: 4,
};


// Styled Dialog component for custom appearance
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(2),
    backgroundColor: '#f9f9f9',
    width: '400px',
  },
  '& .MuiDialogTitle-root': {
    color: '#3f51b5',
    fontWeight: 'bold',
  },
  '& .MuiDialogContentText-root': {
    color: '#333',
    fontSize: '1rem',
    marginBottom: theme.spacing(2),
  },
}));

const CustomButton = styled(Button)({
  color: '#ffffff',
  backgroundColor: '#111827', // Dark slate color
  '&:hover': {
    backgroundColor: '#1f2937', // Slightly lighter shade for hover effect
  },
});
function Chat() {
  const { setLoaderOpen, sheetName, setSheetName, message, email, setMessage, reset_newchat, msgEnd, selectedFile, setSelectedFile, user, get_userhistory, getBaseFilename,awsKeyFile, setAwsKeyFile } = useContext(ContextApp);
  const fileInputRef = useRef(null);
  const [availableSheets, setAvailableSheets] = useState([]);
  const [isExcelFile, setIsExcelFile] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [fileobj, setFileobj] = useState("");
  const [snackopen, setSnackopen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  useEffect(() => {
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.scrollTop += 3000;
    }
  }, [message]);

  useEffect(() => {
    console.log("Updated awsKeyFile:", awsKeyFile);
  }, [awsKeyFile]);

  const handleCloseModal = () => setOpenModal(false);



  const handleSubmit = (event) => {
    if (sheetName) {
      console.log(`File: ${fileobj.name}, Sheet Name: ${sheetName}`);
      handleUpload(fileobj);
    } else {
      alert('Please select a valid sheet name.');
      reset_newchat()
    }
    setOpenModal(false); // Close modal after submission
  };

  const handleSheetChange = (event) => {
    setSheetName(event.target.value);
  };

  const handleFileChange_pdf = async (event) => {
    console.log("im also here")
    if (!event.target.files || event.target.files.length === 0) {
      reset_newchat();
      return;
    }

    // Filter files and check if all are PDFs
    const selectedFiles = Array.from(event.target.files);
    const nonPdfFiles = selectedFiles.filter(file => file.type !== "application/pdf");

    if (nonPdfFiles.length > 0) {
      setDialogMessage("Only PDF files are allowed. Please select a file in PDF format and try again.");
      setOpenDialog(true);
      return;
    }

    const oversizedFiles = selectedFiles.filter(file => file.size > 100 * 1024 * 1024); // 50 MB in bytes
    if (oversizedFiles.length > 0) {
      setDialogMessage("Please upload files smaller than 50 MB.");
      setOpenDialog(true);
      return;
    }

    const zip = new JSZip();
    selectedFiles.forEach((file) => {
      zip.file(file.name, file); // Add each PDF to the ZIP
    });

    try {
      // Generate the ZIP file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Use the first PDF file's name as the ZIP file name
      const zipFileName = `${selectedFiles[0].name.split(".pdf")[0]}.zip`;

      const zipFile = new File([zipBlob], zipFileName, { type: "application/zip" });
      console.log(zipFile)

      setOpenModal(false);
      console.log("Before end of the function")

      // Pass the ZIP file to the handleUpload function
      handleUpload_pdf(zipFile);
    } catch (error) {
      console.error("Error creating ZIP file:", error);
      alert("Failed to create ZIP file. Please try again.");
    }
  };


  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUpload = async (file) => {
    if (true) {
      setLoaderOpen(true);
      try {
        const myfilename = file.name;
        const presignedResponse = await API.postData("/pre_signed_url_for_file", { "file_name_": myfilename });
        const { fields, url, actual_filename } = presignedResponse;

        console.log('Pre-signed URL fields:', fields);
        console.log('Pre-signed URL:', url);
        console.log('Actual filename:', actual_filename);

        const formData = new FormData();
        for (const [key, value] of Object.entries(fields)) {
          formData.append(key, value);
        }
        formData.append('file', file);

        for (let pair of formData.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }

        const uploadResponse = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        console.log('Upload response:', uploadResponse);
        if (uploadResponse.status === 204 || uploadResponse.status === 200) {
          console.log('File uploaded successfully to S3');
        }

        const resp = await API.get("/upload_file_to_s3", { "useremail": email, "uploaded_file_name": actual_filename, "sheet_name": sheetName });
        console.log("File uploaded resp:", resp);
        get_userhistory();
        setMessage([
          {
            text: "Hello **" + user + "**, Let's start using **Engineering Documents Analyzer**",
            isBot: true,
            uploadbutton: true,
          },
          { text: "Currently working on - **File :: " + getBaseFilename(resp.filekey) + "**" + resp.sheetname, isBot: true }
        ]);
        setSelectedFile(resp.filekey);

      } catch (error) {
        console.error("Error uploading file:", error);
        alert("Failed to upload file.");
      }
      finally {
        setLoaderOpen(false);
      }
    }
  };


  const handleUpload_pdf = async (file) => {
    if (true) {
      setLoaderOpen(true);
      if (!file || file.type !== "application/zip") {
        alert("Only ZIP files are allowed.");
        return;
      }
      try {
        const myfilename = file.name;
        console.log(myfilename)
        const bearerToken = localStorage.getItem('msal.idtoken');
        if (!bearerToken) {
          alert("Authorization token is missing. Please log in again.");
          return;
        }
        const presignedResponse = await API.postData("/generate_presigned_url", { "file_name": myfilename },
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`
            }
          }
        );
        const { actual_key, presigned_url } = presignedResponse;
        console.log('Pre-signed URL:', presigned_url);
        console.log('Actual filename:', actual_key);
        setAwsKeyFile(actual_key)

        const formData = new FormData();

        formData.append('file', file);



        const uploadResponse = await axios.put(presigned_url, file, {
          headers: {
            "Content-Type": "application/zip",
          },
          maxBodyLength: Infinity
        });
        console.log('Upload response:', uploadResponse);
        if (uploadResponse.status === 204 || uploadResponse.status === 200) {
          console.log('File uploaded successfully to S3');
        }
        console.log("aws key file-chat comp",awsKeyFile)

        const fetchStatus = async (actual_key) => {
          while (true) {
            try {
              setLoaderOpen(true);
              const response = await axios.post(`${API_BASE_URL}/embeddings_status`,
                { "key": actual_key },
                {
                  headers: {
                    Authorization: `Bearer ${bearerToken}`,
                  },
                }
              );
              if (response.status === 202) {
                console.log("Status 202: Retrying...");
                // Wait for 5 seconds before retrying
                await new Promise((resolve) => setTimeout(resolve, 5000));

                continue; // Retry by continuing the loop
              }

              if (response.status === 200) {
                const result = response.data;
                console.log("Response data:", result);

                if (result.status === "READY") {
                  console.log("Status 200 and READY: Stopping and fetching history.");
                  get_userhistory();
                  setMessage([
                    {
                      text: "Hello **" + user + "**, Let's start using **Engineering Documents Analyzer**",
                      isBot: true,
                      uploadbutton: true,
                    },
                    { text: "Currently working on - **File :: " + file.name + "**", isBot: true }
                  ]);
                  setSelectedFile(file.name);
                  setLoaderOpen(false);
                  break; // Exit the loop
                }
              }

              // If the status code is not 200 or 204, throw an error
              throw new Error(`Unexpected status code: ${response.status}`);

            } catch (error) {
              console.error("Error fetching status:", error);
              break; // Exit the loop on error
            }
          }
        };

        fetchStatus(actual_key);

      } catch (error) {
        console.error("Error uploading file:", error);
      }
      finally {
        setLoaderOpen(false);
      }
    }
  };

  const handlesnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackopen(false);
  };

  // Function to trigger file input click
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const handleFileDialogClose = () => {
    if (!fileInputRef.current.files.length) {
      console.log("File dialog was closed without selecting a file.");
    }
  };

  return (
    <div className="chat-container w-full w-[89%] h-[80%] flex items-center justify-center overflow-y-hidden overflow-y-auto px-1 py-1 scroll">
      <div className="chat-wrapper w-full lg:w-4/5 flex flex-col h-full items-start justify-start">
        <div style={{ marginTop: "5rem" }}></div>
        {user && message?.map((msg, i) => (
          <span
            key={i}
            className={
              msg.isBot
                ? "bot-message flex items-start justify-center gap-2 lg:gap-5 my-2 p-3 rounded-md"
                : "user-message flex items-start justify-end gap-2 lg:gap-5 my-2 p-3 rounded-md"
            }
          >
            {msg.isBot ? (
              <img src={robotIcon} style={{ width: '3rem', height: '3rem' }} />
            ) : (
              <Avatar
                sx={{
                  bgcolor: '#d24a19',
                  width: "3rem",
                  height: '3rem',
                }}
              >
                {user ? user[0].toUpperCase() : 'U'}
              </Avatar>
            )}
            {msg.table && (
              <div style={{ marginRight: "7rem" }} className="table-container">
                <CustomizedTables columns={msg.table.columns} data={msg.table.data} />
                <br />
                <MarkdownFormat text={msg.tableanswer} />
              </div>
            )}

            {msg.chart && (
              <div className="chart-container mt-2">
                <Plot data={msg.chart.data} layout={{ ...msg.chart.layout, width: 950, height: 500 }} />
                {/* <img src={`data:image/png;base64,${msg.chart}`} alt="Chart" style={{ maxWidth: '100%', height: 'auto' }} /> */}
                <br />
                <br />
                <MarkdownFormat text={msg.chartanswer} />
                <br />
                <MarkdownFormat text={msg.chartintr_steps} />
              </div>
            )}

            {/* {msg.intr_steps && <MarkdownFormat text={msg.intr_steps} />} */}
            {msg.text &&
              <div>
                <MarkdownFormat text={msg.text} />
                <br />
                <MarkdownFormat text={msg.textintr_steps} />
              </div>}

            {msg.uploadbutton && (
              <div className="upload-container">
                <input
                  type="file"
                  accept=".pdf"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={handleFileChange_pdf}
                  onClick={(e) => e.target.value = null} // Reset file input to allow reselecting the same file
                  onBlur={handleFileDialogClose} // Detect when the file dialog closes without selection
                  multiple
                />
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleButtonClick}
                  disabled={selectedFile}
                >
                  Upload Pdf file
                </Button>
                {/* Dialog for error messages */}
                <StyledDialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogTitle>Error</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Typography variant="body1" style={{ fontWeight: '500',color:'red' }}>
                        {dialogMessage}
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <CustomButton onClick={handleCloseDialog}>
                      Close
                    </CustomButton>
                  </DialogActions>
                </StyledDialog>
              </div>
            )}
          </span>
        ))}
        <div ref={msgEnd} />
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ display: 'flex', alignItems: 'center' }}>
            <InsertDriveFileIcon sx={{ mr: 1, color: '#1976d2' }} />
            Please Select Sheet Name
          </Typography>

          <Select
            value={sheetName}
            onChange={handleSheetChange}
            fullWidth
            sx={{ mt: 2 }}
          >
            {availableSheets.map((sheet, index) => (
              <MenuItem key={index} value={sheet}>
                {sheet}
              </MenuItem>
            ))}
          </Select>

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              mt: 3,
              bgcolor: '#1976d2',
              '&:hover': { bgcolor: '#1565c0' }
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
      <Snackbar
        style={{ marginTop: "14rem" }}
        open={snackopen}
        autoHideDuration={2000}
        onClose={handlesnackClose}
        message="Just a moment! We're Extracting the sheet names from Excel file..."
        anchorOrigin={{
          vertical: 'top',  // Adjust vertical positioning
          horizontal: 'center',  // Adjust horizontal positioning
        }}
      />
    </div>
  );
}

export default Chat;
