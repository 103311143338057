import jwt_decode from "jwt-decode";

function getUsernameFromToken() {
  const idToken = localStorage.getItem("msal.idtoken");
  if (idToken) {
    try {
      const decodedToken = jwt_decode(idToken);
      if (decodedToken && decodedToken.given_name) {
        console.log("user.js",decodedToken.given_name)
        return decodedToken.given_name;
      } else {
        console.error("Error: Decoded token does not contain username.");
        return null;
      }
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  } else {
    console.error("Error: ID token not found in localStorage.");
    return null;
  }
}

export default getUsernameFromToken;
