import * as React from 'react';
import { useState, useContext } from 'react';
import { Menu, MenuItem } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import LogoutIcon from '@mui/icons-material/Logout';
import IconButton from '@mui/material/IconButton';
import { ContextApp } from "../utils/Context";
import { logout } from "@carrier/auth-module";
import appConfig from "../config";
import logo from '../pics/logo.png';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const { reset_newchat, toggleLeftNav,user } = useContext(ContextApp);

    // Extract initials from username
    const getUserInitials = (name) => {
        return name
            .split(' ')
            .map(word => word[0].toUpperCase())
            .join('');
    };

    const userInitials = getUserInitials(user || "User");

    // Functions to handle opening and closing of the dropdown
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        sessionStorage.removeItem("user");
        logout(appConfig.api);
        console.log('User logged out');
    };

    return (
        <React.Fragment>
            <AppBar color="primary" position="fixed" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">
                        <Grid item style={{ marginRight: '4rem' }}>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleLeftNav}>
                                <MenuIcon style={{ width: 45, height: 35, marginTop: '2px' }} />
                            </IconButton>
                        </Grid>

                        <Grid item xs style={{marginLeft:"-5rem"}}>
                            <img src={logo} alt="Logo" style={{ width: '130px', height: '50px', position: 'relative', left: '50px', top: '0px' }} />
                        </Grid>
                        <Grid item xs>
                            <Typography
                                color="inherit"
                                variant="h5"
                                component="h1"
                                sx={{
                                    fontFamily: "monospace !important",
                                    flex: 1,
                                    textAlign: "left",
                                }}
                            >
                                Engineering Documents Analyzer
                            </Typography>
                        </Grid>
                        <Grid item style={{marginLeft:"28rem"}}>
                            <IconButton onClick={handleMenuOpen}>
                                <Avatar sx={{ width: 45, height: 45, fontSize: "1.8rem", bgcolor: "#111827" }}>
                                    {userInitials}
                                </Avatar>
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                onClick={handleMenuClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                    },
                                }}
                            >
                                <MenuItem disabled>
                                    <Typography sx={{ fontSize: '0.8rem', fontWeight: 'bold' }}>{user}</Typography>
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>
                                    <LogoutIcon fontSize="small" sx={{ mr: 1 }} />
                                    Logout
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
