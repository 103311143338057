import React, { useContext,useEffect } from "react";
import LeftNav from "../components/LeftNav";
import ChatContainer from "../components/ChatContainer";
import Mobile from "../components/Mobile";
import { ContextApp } from "../utils/Context";
import getUsernameFromToken from "../User";

function Home() {

  const username = getUsernameFromToken();
  const { updateUser, user, setMessage, setLoaderOpen,get_userhistory } = useContext(ContextApp);
  console.log(username)

  // Update user information
  useEffect(() => {
    updateUser(username);
  }, [username, updateUser]);

  const get_username = async () => {
    setLoaderOpen(true);
    try {
      setMessage([
        {
          text: `Hello **${user}**, Let's start using **Engineering Documents Analyzer**`,
          isBot: true,
          uploadbutton: true,
        },
      ]);
    } catch (error) {
      console.error("Failed to get user data", error);
    } finally {
      setLoaderOpen(false);
    }
  };

  useEffect(() => {
    if (user) {
      get_username();
    }
  }, [user]);
  
  


  useEffect(() => {
  
    get_userhistory();
  }, []);


  return (
    <div className="flex w-screen relative">
      <LeftNav />
      <ChatContainer />
    </div>
  );
}

export default Home;
